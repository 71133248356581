import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import OrganizationConnectedAccounts from "Components/OrganizationConnectedAccounts";

class OrganizationAccounts extends React.Component {
  render() {
    return (
      <ContentLayout id="settings-members" className="settings">
        <Heading2>Connected accounts</Heading2>
        <PageDescription>
          You can connect your account with these external providers.
        </PageDescription>
        <OrganizationConnectedAccounts />
      </ContentLayout>
    );
  }
}

OrganizationAccounts.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationAccounts;
