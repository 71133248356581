import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import SettingLine from "Components/SettingLine";
import Label from "Components/fields/Label";

const Layout = styled.div`
  a {
    min-width: 185px;
    text-align: right;
    color: ${props => props.theme.links};
    text-decoration: none;
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

class OrganizationConnectedAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      github: false,
      google: false,
      bitbucket: false
    };
  }

  // TODO: Add proper links to connect accounts.

  render() {
    return (
      <Layout>
        <SettingLine
          info={
            <InfoLayout className="account-info">
              <span>
                Provider <strong>Github</strong>
              </span>
              <Label>Not connected</Label>
            </InfoLayout>
          }
          customLink={<Link to="#">Connect with Github</Link>}
          noToggle={true}
        />
        <SettingLine
          info={
            <InfoLayout className="account-info">
              <span>
                Provider <strong>Google</strong>
              </span>
              <Label>Not connected</Label>
            </InfoLayout>
          }
          customLink={<Link to="#">Connect with Google</Link>}
          noToggle={true}
        />
        <SettingLine
          info={
            <InfoLayout className="account-info">
              <span>
                Provider <strong>Bitbucket</strong>
              </span>
              <Label>Not connected</Label>
            </InfoLayout>
          }
          customLink={<Link to="#">Connect with Bitbucket</Link>}
          noToggle={true}
        />
      </Layout>
    );
  }
}

OrganizationConnectedAccounts.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(OrganizationConnectedAccounts);
<Layout>Connected accounts content goes here.</Layout>;
